



















































import {Component, Prop, Vue} from "vue-property-decorator";
import {pdfAccept} from "@/constants/FileConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";
import FileUpload from "@/components/common/FileUpload.vue";
import {namespace} from "vuex-class";
import SignableDocumentDTO from "@/dto/files/SignableDocumentDTO";
import {WorkspaceType} from "@/dto/auth/Workspace";
import UserDTO from "@/dto/UserDTO";
import Workspaces from "@/state/Workspaces";
import SelectOption from "@/components/common/SelectOption";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {ifValid} from "@/utils/ComponentUtils";
import e from "express";

const Auth = namespace("Auth");

@Component({
	components: {
		PortalSelect,
		FileUpload
	}
})
export default class SignableDocumentModal extends Vue {

	private DOCUMENT_ACCEPT = pdfAccept;

	private successful = true;
	private message = "";

	@Prop()
	private file!: SignableDocumentDTO

	@Prop()
	private onSave!: () => void

	private workspace = Workspaces.getCurrent;

	@Auth.State("user")
	private currentUser!: UserDTO;


	onFileLoaded(files: FileMetaDTO[]) {
		if (!files.length) {
			return;
		}
		this.file.primaryDocument = files[0];
	}

	closeModal() {
		this.$modal.hideAll();
	}

	save() {
		this.message = "";
		if (this.file.primaryDocument == null) {
			this.message = "You haven't uploaded the document!" ;
			this.successful = false
		} else {
			ifValid(this, () => {
				this.onSave();
				this.closeModal();
			})
		}
	}

	remove() {
		this.file.primaryDocument = null;
	}

	generateName(): string {
		return this.file.documentSubType
			+ '_' + this.workspace?.name
			+ '_' + new Date().getTime();
	}

	private buildOptions(): Array<SelectOption> {
		switch (this.workspace.type) {
			case WorkspaceType.PERSON:
				return [
					SelectOption.builder().title(("Form NYS_SIGNATURE_AUTHORIZATION")).value("NYS_SIGNATURE_AUTHORIZATION").build(),
					SelectOption.builder().title(("Form 4506_T")).value("FORM_4506_T").build(),
					SelectOption.builder().title(("Form 2848")).value("FORM_2848").build(),
					SelectOption.builder().title(("Form POA_1")).value("POA_1").build(),
					SelectOption.builder().title(("Form TR_579_IT_2022")).value("TR_579_IT_2022").build(),
					SelectOption.builder().title(("Form 8879_2022")).value("FORM_8879_2022").build(),
					SelectOption.builder().title(("Form TR_2000")).value("FORM_TR_2000").build(),
					SelectOption.builder().title(("Form 8453_GEORGIA_2022")).value("FORM_8453_GEORGIA_2022").build(),
					SelectOption.builder().title(("Form 8453_CALIFORNIA_2022")).value("FORM_8453_CALIFORNIA_2022").build(),
					SelectOption.builder().title(("Form 8453_PENNSYLVANIA_2022")).value("FORM_8453_PENNSYLVANIA_2022").build(),
					SelectOption.builder().title(("Form 2553")).value("FORM_2553").build(),
					SelectOption.builder().title(("Form M_8453_MASSACHUSETTS_2022")).value("FORM_M_8453_MASSACHUSETTS_2022").build(),
					SelectOption.builder().title(("Form VA_8879_VIRGINIA_2022")).value("FORM_VA_8879_VIRGINIA_2022").build(),
					SelectOption.builder().title(("Form EL101_MARYLAND_2022")).value("FORM_EL101_MARYLAND_2022").build(),
					SelectOption.builder().title(("Form IL_8453_ILLINOIS_2022")).value("FORM_IL_8453_ILLINOIS_2022").build(),
					SelectOption.builder().title(("Form DR_8454_COLORADO_2022")).value("FORM_DR_8454_COLORADO_2022").build(),
					SelectOption.builder().title(("Form SC_8453_2022")).value("FORM_SC_8453_2022").build(),
					SelectOption.builder().title(("Form AZ_8879_2022")).value("AZ_8879_2022").build(),
				]
			case WorkspaceType.COMPANY:
				return [
					SelectOption.builder().title("Form 8453-C California 2023").value("FORM_8453_C_CALIFORNIA_2023").build(),
					SelectOption.builder().title(("F_1120A")).value("F_1120A").build(),
					SelectOption.builder().title(("Form IL_1120_ST")).value("FORM_IL_1120_ST").build(),
					SelectOption.builder().title(("Form CBT_100_NJ")).value("FORM_CBT_100_NJ").build(),
          SelectOption.builder().title(("NYC_579_COR_2021")).value("NYC_579_COR_2021").build(),
          SelectOption.builder().title(("NYC_579_COR_2022")).value("NYC_579_COR_2022").build(),
					SelectOption.builder().title(("NYC_579_COR_2023")).value("NYC_579_COR_2023").build(),
          SelectOption.builder().title(("TR_579_CT_2021")).value("TR_579_CT_2021").build(),
          SelectOption.builder().title(("TR_579_CT_2022")).value("TR_579_CT_2022").build(),
					SelectOption.builder().title(("TR_579_CT_2023")).value("TR_579_CT_2023").build(),
          SelectOption.builder().title(("TR_579_PT_2022")).value("TR_579_PT_2022").build(),
					SelectOption.builder().title(("Form 8879-C rev. 2021")).value("FORM_8879_C_2021").build(),
					SelectOption.builder().title(("Form 8879-C rev. 2022")).value("FORM_8879_C_2022").build(),
					SelectOption.builder().title(("Form 8879-C rev. 2023")).value("FORM_8879_C_2023").build(),
					SelectOption.builder().title(("Form 8879-S")).value("FORM_8879_S").build(),
					SelectOption.builder().title(("Form 8879-PE rev. 2022")).value("FORM_8879_PE_2022").build(),
					SelectOption.builder().title(("Form 8879-PE rev. 2023")).value("FORM_8879_PE_2023").build(),
					SelectOption.builder().title(("Form PA-8879-C Pennsylvania rev. 2022")).value("FORM_PA_8879_C_PENNSYLVANIA_2022").build(),
					SelectOption.builder().title(("Form PA-8879-P rev. 2022")).value("FORM_PA_8879P_2022").build(),
					SelectOption.builder().title(("Form VA-8879-C rev. 2022")).value("FORM_VA_8879С_VIRGINIA_2022").build(),
					SelectOption.builder().title(("Form 8879-WH")).value("FORM_8879_WH").build(),
					SelectOption.builder().title(("NYC_579_UBTP_2022")).value("NYC_579_UBTP_2022").build(),
					SelectOption.builder().title(("Form TR_2000")).value("FORM_TR_2000").build(),
					SelectOption.builder().title(("Form CT_6")).value("FORM_CT_6").build(),
					SelectOption.builder().title(("Form 8655")).value("FORM_8655").build(),
					SelectOption.builder().title(("Form 8453_C_CALIFORNIA_2022")).value("FORM_8453_C_CALIFORNIA_2022").build(),
					SelectOption.builder().title(("Form 1100_DELAWARE_2022")).value("FORM_1100_DELAWARE_2022").build(),
					SelectOption.builder().title(("Form IT_204_LL_2023")).value("FORM_IT_204_LL_2023").build(),
					SelectOption.builder().title(("Form SC_8453_C_2021")).value("FORM_SC_8453_C_2021").build(),
					SelectOption.builder().title(("Form 1100 Delaware rev. 2023")).value("FORM_1100_DELAWARE_2023").build(),
					SelectOption.builder().title("Form CT-1065/CT-1120SI").value("FORM_CT_1065_1120_CT").build(),
					SelectOption.builder().title("Form 3115 rev. 2022").value("F_3115_2022").build()
				];
			case WorkspaceType.SUBLIME_EMPLOYEE:
				return [];
		}
	}
}
